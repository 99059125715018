import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import { ref, set, get, child } from 'firebase/database';
import { db } from '../../util/firebase';
import { CircularProgress } from '@mui/material';

function JoinLobby() {
  const { lobbyId } = useParams();
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchLobby = useCallback(async () => {
    if (lobbyId && navigate && userId) {
      const dbRef = ref(db);
      try {
        const snapshot = await get(
          child(dbRef, `lobbies/${lobbyId}`)
        );

        if (snapshot.exists()) {
          const lobbySnapshot = snapshot.val();

          if (lobbySnapshot.player2) {
            console.error('This lobby is full');
            navigate('newGame/')
          } else if (lobbySnapshot.player1 === userId) {
            console.error('Cant join lobby twice')
            navigate('newGame/');
          } else {
            try {
              await set(ref(db, 'lobbies/' + lobbyId), {
                ...lobbySnapshot,
                player2: userId,
              });
              set(ref(db, 'users/' + userId), {
                currentGame: lobbyId,
              });
            } catch (error) {
              console.error(error);
              navigate('newGame/');
            }
          }
        } else {
          console.error('lobby not found');
          navigate('newGame/');
        }
      } catch (error) {
        console.error(error);
        navigate('newGame/');
      }
    }
  }, [lobbyId, navigate, userId]);

  useEffect(() => {
    fetchLobby();
  }, [fetchLobby]);

  return <CircularProgress />
}

export default JoinLobby;

import JoinLobby from '../components/joinLobby/joinLobby';
import CreateGame from '../components/createGame/createGame';
import MultiPlayer from '../components/multiPlayer/multiPlayer';

export const routes = () => [
  {
    path: '/join/:lobbyId',
    element: <JoinLobby />,
  },
  {
    path: '/newGame',
    element: <CreateGame />,
  },
  {
    path: '/lobby/:lobbyId',
    element: <MultiPlayer />,
  },
];

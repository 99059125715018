import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { ref, set, push } from 'firebase/database';
import { db } from '../../util/firebase';
import { AuthContext } from '../../providers/AuthProvider';

function CreateGame() {
  const { userId } = useContext(AuthContext);

  async function handleCreateLobby() {
    try {
      const newLobbyRef = push(ref(db, 'lobbies/'));
      await set(newLobbyRef, {
        player1: userId,
      });
      set(ref(db, 'users/' + userId), { currentGame: newLobbyRef.key})
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Button onClick={handleCreateLobby} variant="outlined">
        Create a lobby
      </Button>
    </>
  );
}

export default CreateGame
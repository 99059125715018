import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';

import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'development')
  window.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY
  ),

  isTokenAutoRefreshEnabled: true,
});

const db = getDatabase(app);
const auth = getAuth(app);
const functions = getFunctions(app);

// test functions locally
// connectFunctionsEmulator(functions, '127.0.0.1', 5001);

const callOpenAI = httpsCallable(functions, 'callOpenAI');

export { db, auth, functions, callOpenAI };

import React, { useContext, useEffect, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ref, set, push, onValue } from 'firebase/database';
import { db, callOpenAI } from '../../util/firebase';
import { AuthContext } from '../../providers/AuthProvider';
import { useParams } from 'react-router-dom';

function MultiPlayer() {
  const { lobbyId } = useParams();
  const { userId } = useContext(AuthContext);
  const [story, setStory] = useState([]);
  const [line, setLine] = useState('');
  const [suggestedLine, setSuggestedLine] = useState('');

  useEffect(() => {
    if (lobbyId) {
      const postRef = ref(db, 'posts/' + lobbyId);
      return onValue(postRef, (snapshot) => {
        const postsSnapshot = snapshot.val();
        if (postsSnapshot) {
          setStory(Object.values(postsSnapshot));
        }
      });
    }
  }, [lobbyId]);

  useEffect(() => {
    if (story.length) {
      callOpenAI({
        storyPayload: story
          .map((sentence) => sentence.body)
          .join(' '),
      }).then((result) => {
        const data = result.data;
        setSuggestedLine(data);
      });
    }
  }, [story]);

  function handleSubmit(event) {
    event.preventDefault();
    if (line) {
      const postData = {
        playerId: userId,
        body: line,
      };
      const newPostRef = push(ref(db, 'posts/' + lobbyId));
      try {
        set(newPostRef, postData);
        setLine('');
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleChange(event) {
    setLine(event.target.value);
  }

  return (
    <Stack>
      <form
        onSubmit={handleSubmit}
        style={{ padding: 40, justifyContent: 'center' }}
      >
        <TextField
          id="filled-textarea"
          label={
            story.length ? 'Continue your story' : 'Start your story'
          }
          multiline
          variant="filled"
          value={line}
          onChange={handleChange}
          color={'neutral'}
        />
        <Button
          variant="contained"
          type="submit"
          color="neutral"
          style={{ height: 56, width: 56, marginLeft: 10 }}
          disabled={
            !(
              !story.length ||
              story[story.length - 1].playerId !== userId
            )
          }
        >
          <SendIcon />
        </Button>
      </form>
      <Typography variant="body" color="#787878" fontWeight={'bold'}>
        {suggestedLine}
      </Typography>
      <Stack spacing={2}>
        {story.map((sentence, i) => {
          return (
            <Typography
              variant="body"
              color="#303030"
              fontWeight={'bold'}
              key={i}
            >
              {sentence.body}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default MultiPlayer;

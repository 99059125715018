import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { auth, db } from '../util/firebase';
import { ref, onValue } from 'firebase/database';
import { signInAnonymously } from 'firebase/auth';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext({
  userId: null,
  lobbyId: null,
  setLobbyId: null,
});

function AuthProvider({ children }) {
  const [userId, setUserId] = useState('');
  const [lobbyId, setLobbyId] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const signIn = useCallback(async () => {
    if (navigate) {
      const data = await signInAnonymously(auth);
      const uid = data.user.uid;
      setUserId(uid);

      const userRef = ref(db, 'users/' + uid);
      onValue(userRef, (snapshot) => {
        const userSnapshot = snapshot.val();
        if (userSnapshot && userSnapshot.currentGame) {
          navigate(`lobby/${userSnapshot.currentGame}`);
        }
        setLoading(false);
      });
    }
  }, [navigate]);

  useEffect(() => {
    signIn();
  }, [signIn]);

  if (loading) return <CircularProgress />;

  return (
    <AuthContext.Provider value={{ userId, lobbyId, setLobbyId }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
